/* header.module.css */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff; 
    padding: 0 20px;
    height: 70px;
    box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.2);
  }
  
  .logoContainer {
    min-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    white-space: nowrap;
    margin-left: 40px;
  }
  
  .avatarContainer {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }

  .avatarContainer i{
    font-size: 28px;
    margin-right: 20px;
  }

  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    margin-right: 45px;
    
  }

  .tableFilterButton{
    background-color: #ffffff;
    border: none;
    color: black;
  }

  .tableFilterButton:hover{
    background-color: #ffffff;
    border: none;
    color: black;
    cursor:default;
  }
  .tableFilterButton {
    --bs-btn-active-color: initial;
    --bs-btn-active-bg: initial;
    --bs-btn-active-border-color: initial;
    --bs-btn-active-shadow: none;
  }
  
  .tableFilterButton:active, .tableFilterButton.active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
    box-shadow: var(--bs-btn-active-shadow);
  }
  
  .tableFilterButton:focus, .tableFilterButton.focus {
    outline: none;
    box-shadow: none;
  }
  




  .filters {
    display: flex;
    align-items: center;
    padding: 10px;
    gap:1px;
    justify-content: center;
    width: 90%;
  }
  
  .searchContainer {
    flex-grow: 0.4;
    position: relative;
  }
  
  .searchInput {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }

  .logoutPopup {
    position: absolute;
    right: 0;
    top: 50px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 100;
    width: 150px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    text-align: center;
    padding: 0;
  
  }
  
  .iconContainer{
    display: flex;
    margin-right: 30px;
  }

  .logoutButton {
    width: 100%;
    background-color: #f0f0f0;
    color: black;
    border: none;
    padding: 10px 0;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s ease-in-out;
    font-weight: 500;

  }
  
  .logoutButton:hover {
    background-color: #e5e3e3;
  }
  
  .notificationDot {
    position: relative;
    top: 50%;
    right: -7px;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
  }
  
  .NotificationPopup {
    position: absolute;
    top: 45px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 100;
    width: 200px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 0;
    margin-right: 50px;
  }
  
  .NotificationButton {
    width: 100%;
    background-color: #f0f0f0;
    color: black;
    border: none;
    padding: 10px 0;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s ease-in-out;
    font-weight: 500;
  }
  
  .NotificationButton:hover {
    background-color: #e5e3e3;
  }

  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      padding: 10px;
      height: auto;
    }
  
    .logoContainer {
      justify-content: flex-start;
      margin-bottom: 10px;
    }
  
    .logo {
      margin-left: 0;
      font-size: 20px;
    }
  
    .filters, .avatarContainer {
      width: 100%;
      justify-content: space-between;
    }
  
    .searchContainer {
      flex-grow: 1;
    }
  
    .avatarContainer {
      justify-content: right;
    }
  
    .avatarContainer i, .avatar {
      margin-right: 10px;
    }
  
    .NotificationPopup, .logoutPopup {
      right: 10px;
    }
  }