.tabContainer {
    padding: 20px;
    background-color: #fdfdfd;
    border-radius: 8px;
    width: 90%;
    margin: 0 auto;
  }
  
  .ongoingSession {
    margin-top: 20px;
  }

  .timings{
    display: flex;
    float: right;
    gap: 20px;
  }

  .time{
    margin-top: 5px;
  }
  
  .courseItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin: 10px 0;
    background-color: #ffffff;
    border-radius: 8px;
  }
  
  .courseTitle {
    font-size: 1.2em;
  }
  
  .registerSession {
    margin-top: 20px;
  }
  