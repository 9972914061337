.tableContainer {
  margin: 20px;
  overflow-x: auto;
  border-radius: 8px;
}

.table {
  width: 100%;
  margin-top: 1rem;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-collapse: separate;
  border-spacing: 0;
}

th, td {
  text-align: left;
  padding: 6px 10px;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid rgba(209, 196, 233, 0.3);
}

th {
  background-color: #252243;
  color: #f1f1f1;
  font-weight: 500;
  padding: 12px 20px;
}

tr:nth-child(odd) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #e8e8e8;
}

.table thead tr {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
th:last-child, td:last-child {
  border-right: none;
}



.defaultText {
  color: #888;
  font-style: italic;
}

.cellLink {
  cursor: pointer;
  color: #0056b3;
  text-decoration: none;
}

.cellLink:hover {
  text-decoration: underline;
}

.MuiPagination-root, .MuiTablePagination-root {
  font-size: 0.875rem;
}

.Mui-selected {
  background-color: #007bff;
  color: white;
}

.paginationControls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
}
  
  .acceptButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .acceptButton:hover {
    background-color: #0056b3;
  }
  

  .centerAlign{
    text-align: center;
  }

  .noticeUrl{
    color: #3e33b8;
    font-size: 20px;
  }

  .registrationNumberInput, .dateInput, .remarksInput {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: calc(100% - 16px);
    box-sizing: border-box;
    transition: border-color 0.2s ease-in-out;
  }

  .remarksInput {
    font-size: 15px;
    overflow: hidden;
    min-width: 250px;
  }
  
  .registrationNumberInput:focus, .dateInput:focus {
    border-color: #007bff;
    outline: none;
  }
  

  .applyFilterButton{
    float: right;
  }

  .offcanvasContainer {
    padding: 20px;
    background-color: #f1f1f1;
    opacity: 0.95;
   
  }
  
  .offcanvasTitle {
    
  }
  
  .offcanvasCheckboxContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .formCheck {
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(200, 200, 200);
    padding: 15px;
  }
  
  .formCheckInput {
    cursor: pointer;
    size: 20px; 
  }


  .formCheckLabel {
    margin-left: 10px; 
    cursor: pointer;
  }
  
  .checkboxInput {
    margin-right: 0px;
  }

  .filters {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 10px;
    justify-content: right;
  }
  
  .remarksInput:focus {
    border-color: #007bff;
    outline: none;
  }
  
  input[type="text"], input[type="date"] {
    font-size: 16px;
  }

  .tableContainer {
    overflow-x: auto;
  }

  .registrationNumberInput {
    max-width: 170px;
    width: auto;
  }

  .bigCheckbox {
    transform: scale(1.25);
    margin: 10px;
  }
  
  .floatingSubmitButton {
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 7px 14px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    justify-content: center;
    align-items: center;
    font-size: 17px;
    margin-right: 5px;
    border-radius: 3px;
  }
  .floatingSubmitButton:hover {
    cursor: pointer;
    background-color: #0d64c0;
  }

  .floatingButtons{
    /* text-align: center; */
  }

 
  @media (max-width: 768px) {
    .remarksInput {
      font-size: 14px;
      padding: 6px;
    }
  
    .table, th, td {
      font-size: 14px;
    }
    .remarksInput, input {
        font-size: 14px;
        padding: 6px;
      }
    
      .table, th, td {
        font-size: 14px;
      }
  }