.css-x3x7nz{
    padding: 0;
}

@media (max-width: 600px) {
    .courseImg {
      display: none !important;
    }

    .courseName{
      font-size: 15px;
    }
  }