.layout {
    display: flex;
    justify-content: space-between;
    margin: 0;
  }
  
  .cardContainer {
    flex: 1;
    padding-right: 20px;
    margin-bottom: auto;
  }
  
  .noticeBoardContainer {
    flex: 2;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .noticeBoardTitle {
    color: #062660;
    margin-bottom: 20px;
    font-size: 1.5rem;
    text-align: left;
    text-align: center;
  }

  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modalContent:focus { outline: none; }
  
  .modalContent {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    width: 90%;
    position: relative;
    z-index: 1001;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }

  .modalHeader {
    width: 100%;
    text-align: right;
  }
  
  .closeButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #333;
  }
  
  .modalBody {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .textArea {
    width: 100%;
    margin-top: 20px;
  }
  
  .submitButton {
    margin-top: 20px;
    background-color: #007bff;
    color: white;
  }

  .paginationControls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 10px;
  }

  .layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .cardContainer,
  .noticeBoardContainer {
    width: 100%;
  }
  
  @media (min-width: 768px) {
    .layout {
      flex-direction: row;
    }
    
    .cardContainer {
      flex: 1;
      padding-right: 20px;
      margin-top: 20px;
    }
    
    .noticeBoardContainer {
      flex: 2;
      margin: auto 0;
    }
  }
  