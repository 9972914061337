.profileContainer {
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    margin: 20px auto;
  }
  
  .profilePictureContainer {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 50%;
  }
  
  .profilePicAndBasicInfoContainer {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 50px;
  }
  
  .profilePicture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .alertBanner{
    width: 100%;
  }

  

  

  .editableProfilePicture:hover{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    opacity: 0.7;
  }
  
  .basicInfo {
    flex: 1;
    background-color: #ffffff;
    border-radius: 8px;
  }
  
.buttonContainers{
  gap:5px
}

  .field {
    margin-bottom: 15px;
  }
  
  .field label {
    display: block;
    margin-bottom: 7px;
    font-weight: 600;
    color: #333;
  }

  .field input {
    width: calc(100% - 16px);
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  
  .field input:focus {
    border-color: #007bff;
    outline: 0;
  }
  
  h2 {
    color: #000000;
    margin-bottom: 20px;
  }
  
  .academicInformation {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    margin-top: 30px;
  }

  .profilePictureContainer {
    width: 300px;
    height: 250px;

    background-image: url('../../assets/images/loading.webp');
    background-size: cover;
    background-position: center;
  }
   
  .profilePictureContainer img {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .academicInfo {
    width: 90%;
    margin: 0 auto;
  }

  .verticalLine {
    height: 350px; 
    width: 1px;
    background-color: #ececec; 
    margin: auto 0;
  }
  
  .horizontalLine {
    border: 0;
    height: 2px;
    background-color: #b9b9b9;
    margin: 20px 0;
  }
  
  .profileName {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold; 
    color: #333;
    text-align: center;
  }

  .profileDescription{
    font-size: 17px;
    font-weight: 500; 
    color: #333;
    text-align: center;
  }

  .editButton, .saveButton {
    cursor: pointer;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    float: right;
    font-size: 16px;
    background-color: #f0f0f0;

  }
  
  .saveButton {
    background-color: #28a745;
    color: white;
  } 

  .uploadIconLabel{
    float: right;
    cursor: pointer;
    font-size: 20px;
  }

  .marksheetUploadIconLabel{
    float: right;
    cursor: pointer;
    font-size: 20px;
  }

  .cancelButton {
    cursor: pointer;
    padding: 10px 20px;
    border: none;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 16px;
    background-color: #f44336;
    color: white;
    float: right;
  }
  .error {
    color: #dc3545;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 500;
  }
  
  .field select {
    width: calc(100% - 16px);
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .field select:focus {
    border-color: #007bff;
    outline: 0;
  }

  .allMarkSheetContainer{
    display: flex;
  }

  .marksheetContainer {
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: center;
    max-width: 400px;
    min-width: 400px;
    max-height: 500px;
    min-height: 500px;
    margin: 0 auto;
  }
  
  .marksheetImage {
    padding: 20px;
    max-width: 100%;
    min-width: 100%;
    min-height: 100%;
    height: auto;
    max-height: 100%;
    border-radius: 4px;
  }

  .marksheetImage:hover{
    cursor: pointer;
    opacity: 0.7;
  }
  
  .profileContainer::-webkit-scrollbar {
    width: 10px; /* Scrollbar width */
  }
  
  .profileContainer::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
  }
  
  .profileContainer::-webkit-scrollbar-thumb {
    background: #888; /* Handle color */
  }
  
  .profileContainer::-webkit-scrollbar-thumb:hover {
    background: #555; /* Handle color on hover */
  }
  
  @media (max-width: 768px) {
    .profilePicAndBasicInfoContainer, .pictureAndLabel {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }


  .marksheetUploadIconLabel{
    float: right;
    cursor: pointer;
    font-size: 22px;
  }
  
    .profilePictureContainer {
      width: 180px;
      height: 180px;
      margin-top: 10%;
    }
  
  
    .profileName {
      font-size: 18px;
    }
  
    .verticalLine {
      display: none;
    }
  
    .basicInfo, .academicInformation {
      width: 100%;
      padding: 15px;
    }
  
    .field input, .field label {

    }
    .formControl{
      border-radius: 3px;
    }
  
    .horizontalLine {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  
    h2 {
      text-align: center;
    }
  }
  @media (max-width: 768px) {
    .profilePictureContainer {
      width: 150px;
      height: 150px;
      margin-top: 8%;
    }
  }
  
  @media (max-width: 480px) {
    .profilePictureContainer {
      width: 120px;
      height: 120px;
      margin-top: 5%;
    }
  }
  
  