
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.modalContent:focus { outline: none; }

.modalContent {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  padding: 25px;
  max-width: 600px;
  width: 90%;
  position: relative;
  z-index: 1051;
  display: flex;
  flex-direction: column;
  height: 60vh;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.closeButton {
  border: none;
  background: none;
  color: #666;
  font-size: 28px;
  cursor: pointer;
  opacity: 0.6;
}

.closeButton:hover {
  opacity: 1;
}

.modalBody {
  padding: 20px 15px;
  overflow-y: auto;
}

.label {
  font-weight: bold;
  color: #333;
  margin-right: 5px;
}

.value {
  color: #555;
}

.detailsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.col-md-6 {
  padding: 10px;
}

.noticeLinks {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
}

.noticeLinkItem {
  display: flex;
  align-items: center;
  color: #007bff;
  font-size: 0.9rem;
  text-decoration: none;
}

.noticeLinkItem:hover, .noticeLinkItem:focus {
  text-decoration: underline;
  color: #0056b3;
}

.label {
  font-weight: bold;
  color: #333;
  margin-right: 5px;
}

.value {
  color: #555;
}

.detailsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.colDetail {
  padding: 10px;
}

@media (max-width: 768px) {
  .detailsRow {
    flex-direction: column;
  }
}
