.paymentContainer {
    display: flex;
    justify-content: space-between;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    max-width: 1200px;
    margin: 40px auto;
    font-family: 'Arial', sans-serif;
  }
  
  .qrContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
  
  .qrImage {
    max-width: 100%;
    height: auto;
    border: 2px solid #ddd;
    border-radius: 8px;
  }
  
  .detailsContainer {
    flex: 2;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
  }
  
  .courseTitle {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .courseDuration {
    font-size: 16px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .instructorName {
    font-size: 16px;
    margin-bottom: 5px;
    color: #333;
  }
  
  .registrationDate {
    font-size: 14px;
    color: #333;
  }

  .paymentAmount{
    font-size: 20px;
    color: #c00000;
  }
  
  .paymentInstructions {
    margin-top: 20px;
    font-size: 16px;
    color: #333;
  }
  
  .paymentInstructions h3 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .paymentInstructions ol {
    padding-left: 20px;
  }
  
  .paymentInstructions ol li {
    margin-bottom: 10px;
    color: #333;
  }
  
  .paymentInstructions ol li:last-child {
    color: red;
    font-size: 15px;
    font-weight: 500;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  