.noDataFoundContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 200px;
    position: relative;
  }
  
  .lottieAnimation {
    width: 40%;
    max-width: 350px;
    min-width: 300px;
    margin-bottom: 20px;
  }
  
  .successText {
    font-size: 24px;
    color: #14c700;
    font-weight: 600;
    text-align: center;
  }
  
  .successButton {
    text-decoration: none;
  }
  
  .redirectButton {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.3s;
  }

  .errorContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fbef72;
    color: #ad6b02;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.errorContainer p {
    margin: 0;
    padding: 20px;
    border: 2px solid #FDD835;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    background-color: #FFF9C4;
}



  
  .redirectButton:hover {
    background-color: #45a049;
    transform: scale(1.05);
  }
  