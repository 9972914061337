.error {
  color: rgb(238, 0, 0);
  font-size: 0.9rem;
  margin-top: 5px;
  font-weight: 500;
 
}



.formContainer{

}

.rowContainer{
  --bs-gutter-y: 1rem;
  --bs-gutter-x: 0.8rem;
}

.checkboxContainer {
  display: flex;
  flex-wrap: wrap;
}

.checkboxItem {
  margin-right: 20px; 
}

.checkbox {
  width: 15px; 
  height: 15px; 
  margin-right: 5px; 
}

.submitButton{
  text-align: center;
}

.formControl{
  border-radius: 3px;
}
.toggleContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;
  margin-bottom: 20px;
}

.toggleButtons {
  display: flex;
  margin: 0 5px;
}
.Submitbutton{
  background-color: #4CAF50;
  border-radius: 4px;
  transition-duration: 0.4s;
  cursor: pointer;
  padding: 10px 20px;
  margin-top: 30px;
  border: none;
}

.Submitbutton:hover{
  background-color: #45a049;
}


.toggleButton {
  padding: 10px 20px;
  border: none;
  background-color: #c0bfc9;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 3px; 
  margin: 0 1px;
}

.toggleButton.active {
  background-color: #4CAF50; 
  color: white;
  box-shadow: 0 2px 4px rgb(164, 165, 169); 
}

.toggleContainer span {
  font-size: 1.2rem;
  color: #333;
  font-weight: bold;
}

.boldLabel {
  font-weight: 450;
  margin-top: 5px;
  margin-bottom: 0rem;
}

.loading {
  font-size: 24px;
  color: #000000;
  text-align: center;
  padding: 20px;
  animation: pulse 1s infinite alternate;
}

.fetchError {
  font-size: 24px;
  color: #FF5722; 
  text-align: center;
  padding: 20px;
}
 
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}



