.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent:focus { outline: none; }

.modalContent {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 2rem;
  max-width: 1250px;
  width: 90%;
  position: relative;
  z-index: 1001;
  overflow-y: auto;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.95;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 0 10px;
}

.modalHeader {
  width: 100%;
  text-align: right;
  border-bottom: 1px solid #e7e6e6;
  padding: 10px;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #333;
}

.modalBody {
  display: block;
  width: 100%;
}

.aboutCollege {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  margin-top: 1rem;
}

.modalImage {
  max-width: 350px;
  min-width: 350px;
  min-height: 250px;
  max-height: 250px;
  border-radius: 8px;
  object-fit: contain;
}

.modalImage img{
  height: 100%;
  width: 100%;
}

.modalDetails {
  flex: 1;
  max-width: calc(60% - 20px);
  padding-left: 20px;
}

.modalTitle, .modalLocation, .modalCourses, .modalDescription {
  margin-bottom: 0.5rem;
}

.modalTitle {
  font-size: 2rem;
}

.modalLocation, .modalCourses {
  font-size: 1.1rem;
}

.modalCourses{
  font-style: italic;
}

.modalDescription {
  font-size: 1rem;
}

.tableContainer {
  max-height: 230px;
  overflow-y: auto;
  overflow-x: hidden;
}


.tableContainer::-webkit-scrollbar {
  width: 8px;
}

.tableContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.tableContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.modalImageContainer {
  background-image: url('../../assets/images/loading.webp');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalImage {
  opacity: 0;
  height: auto;
  transition: opacity 0.3s ease-in-out;
}



@media (max-width: 768px) {
  .modalBody, .aboutCollege {
    flex-direction: column;
    align-items: center;
  }

  .modalImage {
    width: 80%;
    max-width: none;
    margin-bottom: 1rem;
  }

  .modalDetails {
    max-width: 100%;
    padding-left: 0;
  }
}
