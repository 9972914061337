
.container {
  display: flex;
  flex-direction: column;
  height: 45vh;
}

.mainContent {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.hero {
  text-align: center;
}

.mainContent::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 0;
  }
  
  .hero {
    position: relative;
    z-index: 1;
    color: white;
  }

.heroTitle {
  color: #ffffff;
  font-size: 32px;
  margin-bottom: 20px;
}

.heroText {
  color: #fbfbfb;
  margin-bottom: 30px;
}

.heroBtn {
  background-color: #005A87;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}


.socialLinks {
  margin-top: 10px;
}

.socialLink {
  color: #ffffff;
  margin: 0 10px;
  font-size: 20px;
  transition: color 0.3s;
}

.socialLink:hover {
  color: #aaa;
}

.featuredSchoolsHeader {
  text-align: center;
  margin: 40px 0 20px;
  padding: 10px;
}

.featuredSchoolsHeader h3 {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: #222;
  padding-bottom: 10px;
}

.featuredSchoolsHeader h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 2px;
  background-color: #4b8bec;
}

.cardsContainer {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background: #ffffff;
    height: 50vh;
  }
  
  .cardLabel {
    font-family: 'Montserrat', sans-serif;
    color: #005A87;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
  }

  .card {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 20px;
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
  }

  .cardLabel {
    font-family: 'Montserrat', sans-serif;
    color: #005A87;
    font-size: 16px;
    font-weight: bold;
    margin-top: 12px;
    text-align: center;
    width: 100%;
  }
  
  
  .aboutUsContainer {
    text-align: left;
    margin: 60px 0 80px;
  }
  
  .aboutUsContainer p {
    color: #555;
    font-size: 16px;
    line-height: 1.6;
    width: 80%;
    margin: auto;
    text-align: center;
  }

  @media (max-width: 768px) {
    .container {
      height: auto;
    }
  
    .mainContent {
      padding: 20px;
    }
  
    .heroTitle {
      font-size: 24px;
    }
  
    .heroText {
      font-size: 14px;
    }
  
    .heroBtn {
      padding: 8px 16px;
    }
  
    .cardsContainer {
      flex-direction: column;
      height: auto;
    }
  
    .card {
      flex-basis: 100%;
      margin-bottom: 20px;
    }
  
    .aboutUsContainer p {
      width: 95%;
    }
  
    .featuredSchoolsHeader h3 {
      font-size: 20px;
    }
  }
  