.tableContainer {
  margin: 20px;
  overflow-x: auto;
  border-radius: 8px;
}

.table {
  width: 100%;
  margin-top: 1rem;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-collapse: separate;
  border-spacing: 0;
}

th, td {
  font-size: 13px;
  text-align: left;
  padding: 6px 15px;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid rgba(209, 196, 233, 0.3);
}

th {
  background-color: #252243;
  color: #f1f1f1;
  font-weight: 500;
  padding: 12px 20px;
}

tr:nth-child(odd) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #e8e8e8;
}

.table thead tr {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
th:last-child, td:last-child {
  border-right: none;
}



.paginationControls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
}


.applyButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.applyButton:hover {
  background-color: #0056b3;
}

.applyButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.applyButton:hover {
  background-color: #0056b3;
}


.applyFilterButton {
  float: right;
}



.offcanvasContainer {
  padding: 20px;
  background-color: #f1f1f1;
  opacity: 0.95;

}

.offcanvasHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.offcanvasTitle {
  font-size: 17.5px;
  font-weight: 400;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 15px;
}

.offcanvasCheckboxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formCheck {
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(200, 200, 200);
  padding: 15px;
}

.formCheckInput {
  cursor: pointer;
  size: 20px;
}


.formCheckLabel {
  margin-left: 10px;
  cursor: pointer;
}

.checkboxInput {
  margin-right: 0px;
}

.filters {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  justify-content: right;
}


.centerAlign {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editButton {
  color: #3e33b8;
  margin-left: 15px;
  font-size: 17px;
  margin-bottom: 5px;
}

.editButton:hover {
  cursor: pointer;
}

.noticeUrl {
  color: #3e33b8;
  font-size: 19px;
  font-weight: 550;
}

.applyFilterButton {
  float: right;
}

.dateFilterContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  margin-right: auto;
  margin-top: 20px;
}

.dateFilterLabel {
  color: #333;
  margin-bottom: 5px;
  font-weight: 500;
}

.dateFilterInput {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
}