.noticeBoard {
  padding: 20px;
  overflow-y: auto;
  height: 100vh;
}


.noticeSection {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid #BECDE2;
  border-radius: 8px;
  overflow: hidden;
}

.paginationControls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
}

.noticeBoardTitle {
  color: #062660;
  margin-bottom: 20px;
  font-size: 1.25rem;
  text-align: left;
}

.noticeDate {
  font-size: 0.85rem;
  color: #307AFF;
  white-space: nowrap;
}

.noticeLink {
  color: #307AFF;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 70%;
  white-space: nowrap;
}

.message {
  max-width: 70%;
  text-align: center;
  margin-bottom: 0;
  margin-left: 5px;
}

.noticeLink:hover {
  text-decoration: underline;
}

