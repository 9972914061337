.adminContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    padding: 24px;
    max-width: 1200px;
    margin: auto;
}

.form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.submitButton, .uploadButton{
    margin-top: 20px;
}

.uploadButton{
    background-color: #e0e0e0 !important;
    color: black !important;
    cursor: pointer;
    margin-right: 10px !important;
}

.uploadButton:hover{
    background-color: #b7b5b5;
    color: black;
    cursor: pointer;
}

.error {
    color: red;
    margin-top: 10px;
}

input[type="file"] {
    display: none;
}
