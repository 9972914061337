.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: row;
}

.logoSection,
.signInSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    overflow: hidden;
}

.logoSection {
    background-color: #1d1a36;
    width: 90%;
    height: 100vh;
    padding: 0;
}

.signInSection {
    width: 10%;
}

.logo {
    width: 400px;
    margin-bottom: 20px;
}


.logoSection h1,
.logoSection p {
    color: white;
    text-align: center;
}

.signInSection {
    width: 30%;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    padding: 20px;
}


.footer {
    padding-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-top: 1px solid #ddd;
  }
  

  .contentWrapper{
    margin-top: 50px;

  }

  .footerLogo {
    width: 50px;
    margin-bottom: 8px;
  }
  
  .footerText h3, .footerText p {
    margin: 0;
    text-align: center;
    color: #666;
  }
  
  .footerText h3 {
    font-size: 18px;
    margin-bottom: 4px;
  }
  
  .footerText p {
    font-size: 14px;
  }
  
  .googleButtonLarge {
    font-size: 19px;
    --google-button-height: 60px;
    --google-button-width: 240px;
  }
  
  .googleButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .swiperSlide {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .slideImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(35%);
  }
  
  .slideContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
    box-sizing: border-box;
  }
  
  .slideTitle {
    font-size: 60px;
    font-weight: bold;
    color: #fff;
    margin: 0 0 10px 0;
  }
  
  .slideDescription {
    font-size: 18px;
    margin: 0;
  }
  

@media (max-width: 768px) {
  .loginContainer {
      flex-direction: column;
  }

  .logoSection,
  .signInSection {
      width: 100%;
      padding: 20px;
      height: auto;
      box-sizing: border-box;
  }

  .logo {
      width: 200px;
  }

  .logoSection {
      padding: 0px;
  }

  .signInSection {
      padding: 20px;
  }

  .googleButtonLarge {
      --google-button-height: 48px;
      --google-button-width: 200px;
  }

  .contentWrapper {
      margin-top: 20px;
  }

  .footerLogo {
      width: 40px;
  }

  .footerText h3 {
      font-size: 16px;
  }

  .footerText p {
      font-size: 12px;
  }
}

  
