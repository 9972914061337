.offcanvasContainer {
    width: 500px;
    padding: 1rem;
    background-color: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  
  .offcanvasCheckboxContainer,
  .dateFilterContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .formCheck {
    margin-bottom: 0.5rem;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
    border-bottom: 1px solid rgb(222, 222, 222);
  }
  
  .formCheckLabel {
    margin-left: 0.5rem;
    font-size: 13px;
    cursor: pointer;
    color: #2f3337;
  }

  .Title{
    margin: 0 auto;
    font-size: 1.5rem;
  }

  .dateFilterLabel {
    font-size: 16px;
    color: #495057;
    margin-bottom: 0.25rem;
  }

  .offCanvasTitle{
    color: #495057;
    font-size: 18px;
    text-decoration: underline;
  }

  .offCanvasTitleDate{
    color: #495057;
    margin-top: 30px;
    font-size: 18px;
    margin-bottom: 10px;
    text-decoration: underline;
  }
  
  
  .button {
    margin-top: 1rem;
    width: 100%;
    background-color: #007bff;
    border-color: #007bff;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-size: 1rem;
    line-height: 1.5;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  }


  .searchBar{
    display: flex;
  }

  .tableFilterButton{
    color: #495057;
    background-color: #ffffff;
    border: none;
  }

  .tableFilterButton:hover{
    color: #495057;
    background-color: #ffffff;
    border: none;
    cursor: default;
  }

  .tableFilterButton {
    --bs-btn-active-color: initial;
    --bs-btn-active-bg: initial;
    --bs-btn-active-border-color: initial;
    --bs-btn-active-shadow: none;
  }
  
  .tableFilterButton:active, .tableFilterButton.active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
    box-shadow: var(--bs-btn-active-shadow);
  }
  
  .tableFilterButton:focus, .tableFilterButton.focus {
    outline: none;
    box-shadow: none;
  }

  .collegeCheckbox{
    margin-top: 25px;
    margin-left: 10px;
  }

  .collegeCheckbox label:hover{
    cursor: pointer;
  }


  .searchInput{
    width: 80%;
  }
  
  .applyFilterButton{
    margin-top: 20px;
    float: right;
  }

  .button:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
  @media (max-width: 575.98px) {
    .offcanvasContainer {
      width: 100%;
      box-shadow: none;
    }
  }
  