
.table {
    width: 98%;
    margin: 30px auto;
    border-collapse: collapse;
    background-color: white;
  }
  
  .thead {
    background-color: #f5f5f5;
  }
  
  .tr {
    border-bottom: 1px solid #e1e1e1;
  }

  .description{
    margin-top: 10px;
  }


  .logoImage{
    min-width: 250px;   
    max-width: 250px; 
  
    max-height: 150px;  
    object-fit: contain; 
    border-radius: 4px;
  }
  
/* 
  .tdDetails{
    min-width: 1000px;
  
  } */

  
  .tbody tr {
    transition: background-color 0.3s ease;
  }
  
  .tbody tr:hover {
    background-color: #f9f9f9;
  }
  
  .tbody td {
    padding: 10px 15px;
    font-size: 14px;
    color: #666;
    border-bottom: 1px solid #e1e1e1;
  }
  
  .applyButton {
    background-color: #4CAF50; 
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
  }

  @media (max-width: 768px){
    .description {
      display: none;
    }
    .tdDetails{
      min-width: 0px
    
    }
}
  
  .applyButton:hover {
    background-color: #45a049;
  }

  .details{
    font-size: 16px;
    display: flex;
  flex-direction: column;
  cursor: pointer;


  }
  


  .title{
  font-size: 18px;
  cursor: pointer;  
  font-weight: bold;
   
  }

  .title:hover{
    font-size: 18.12px;
    color: rgb(75, 75, 123);
     
    }

  .location{
   
  }

  .description{
    text-align: justify;
  }
  

  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  
  .tableContainer {
    display: flex;
    flex-direction: column;
  }
  
  .dropdownButton {
    padding: 10px;
    background-color: #f0f0f0;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .dropdownContent {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0; 
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding: 10px;
    border-radius: 4px;
  }
  
  .dropdownItem {
    margin: 5px 0;
    cursor: pointer;
  }
  
  .applyButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .applyButton:hover {
    background-color: #0056b3;
  }
  
  
  .applyFilterButton{
    float: right;
  }


  .offcanvasContainer {
    padding: 20px;
    background-color: #f1f1f1;
    opacity: 0.95;
   
  }
  
  .offcanvasTitle {
    
  }
  
  .offcanvasCheckboxContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .formCheck {
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(200, 200, 200);
    padding: 15px;
  }
  
  .formCheckInput {
    cursor: pointer;
    size: 20px; 
  }


  .formCheckLabel {
    margin-left: 10px; 
    cursor: pointer;
  }
  
  .checkboxInput {
    margin-right: 0px;
  }

  .filters {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 10px;
    justify-content: right;
  }
  
  .searchContainer {
    
    flex-grow: 0.7;
    position: relative;
  }
  
  .searchInput {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .filterButtonContainer {
  }
  
  .tableFilterButton {
    background-color: #294cc1;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    position: relative;
  }

  .tableFilterButton:hover{
    background-color: #132b7b;
  }

  .dropdown {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000; 
  }

  .dropdown {
    position: absolute;
    top: 100%; 
    left: 0;
    width: 100%;
    z-index: 1000; 

  }
  
  .dropdownItem {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .dropdownItem:hover {
    background-color: #f7f7f7;
  }
  .logoImageContainer {
    background-image: url('../../assets/images/loading.webp');
    background-size: cover;
    background-position: center;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logoImage {
    opacity: 0;
    max-width: 150px;
    max-height: 100px;
    min-width: 150px;
    min-height: 100px;
    margin-left: 30px;
    transition: opacity 0.3s ease-in-out;
  }

  .paginationControls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 10px;
  }
  
  