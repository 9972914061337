.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent:focus {
  outline: none;
}

.hyperlineButtons{
  display: flex;
}

.modalContent {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 20px;
  max-width: 950px;
  height: auto;
  width: 90%;
  position: relative;
  z-index: 1001;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.aboutInstructor {
  display: flex;
  margin-bottom: 10px;
}

.instructorImage {
  min-width: 60px;
}

.instructorImage img {
  width: 60px;
  height: 60px;
  border: 2px solid black;
  object-fit: cover;
  border-radius: 50%;
}

.instructorDetails {
  padding-left: 20px;
}

.showMoreButton {
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
}

.flexbox {
  display: flex;
  gap: 20px;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.courseImage {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.imageContainer {
  max-width: 400px;
  min-width: 400px;
}

.modalHeader {
  width: 100%;
  text-align: right;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #333;
}

.modalBody {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.submitButton {
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  margin-left: auto;
}

.submitButton:hover {
  background-color: #0063ce;
  color: white;
}

@media (max-width: 600px) {
  .modalContent {
    width: 100%;
    padding: 10px;
  }

 .instructorImage {
    max-width: 100px;
    min-width: 100px;
    border-radius: 3px;
  }

  .imageContainer{
    max-width: 300px;
    min-width: 300px;
  }

  .flexbox{
    flex-direction: column;
    align-items: center;
  }

  .aboutInstructor {
    flex-direction: column;
    align-items: center;
  }

  .instructorDetails {
    padding-left: 0;
  }

  .gridContainer {
    margin-left: -100px;
    width: calc(100% + 50px);
  }
  

}
