.offcanvasHeader {
  color: black;
  padding: 16px 24px;
  border-bottom: 1px solid #e7e6e6;
  font-size: 22px;
}.offcanvasContainer {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .offcanvasHeader {
    color: #212529;
    padding: 20px 24px;
    border-bottom: 2px solid #dee2e6;
    font-size: 24px;
    background-color: #f8f9fa;
  }
  
  .offcanvasHeader .btn-close {
    filter: invert(0.5);
  }
  
  .detailsContainer {
    padding: 24px;
    border-radius: 10px;
    background-color: #ffffff;
    margin-bottom: 24px;
  }

  .confirmButton {
    background-color: #007bff;
    color: #ffffff;
    border-radius: 8px;
    width: 20%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: none;
    transition: background-color 0.3s, box-shadow 0.2s;
    margin-left: auto;
    padding: 15px;
    font-size: 17px;
    display: block;
    margin-right: 20px;
  }

  .offcanvasBodyOverride {
    padding: 0 !important;
  }
  
  .confirmButton:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  

.offcanvasHeader .btn-close {
  filter: invert(1);
  color: #ffffff;
}

.applicationDetails{
    font-size: 19px;
}

.detailsContainer {
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 20px;
}


.buttonContainer {
    position: sticky;
    bottom: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 3px solid #dee2e6;
    background-color: #fff;
  }

.applicationDetails {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 24px;
  padding: 24px;
  border-radius: 10px;

  width: 100%;
  line-height: 1.8;
}

.applicationDetails div {
  width: 48%;
}

.applicationDetails p {
  margin-bottom: 12px;
  font-size: 16px;
  color: #333;
}

.applicationDetails strong {
  font-weight: 600;
  color: #004085;
  font-size: 16px;
  display: inline-block;
  margin-right: 4px;
}

.offcanvasBodyOverride::-webkit-scrollbar {
  width: 8px;
}
 
.offcanvasBodyOverride::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}
 
.offcanvasBodyOverride::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.offcanvasBodyOverride::-webkit-scrollbar-thumb:hover {
  background: #555;
}




