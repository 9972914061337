.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modalContent:focus { outline: none; }
  
  .modalContent {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    width: 90%;
    position: relative;
    z-index: 1001;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }
  
  .modalHeader {
    width: 100%;
    text-align: right;
  }
  
  .closeButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #333;
  }
  
  .modalBody {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .textArea {
    width: calc(100% - 20px);
    margin: 20px 0; 
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 100px;
    resize: vertical;
  }
  
  .submitButton {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-weight: bold; 
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  
  .tableContainer {

  }
 
  .modalBody > span {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px; 
  }
  