.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modalContent:focus { outline: none; }
  
  .modalContent {
    background-color: #ffffff;
    border-radius: 3px;
    padding: 20px;
    width: 90%;
    max-width: 1000px;
    height: 80vh;
    position: relative;
    display: flex;
    z-index: 1001;
  }
  
  .card {
    display: flex;
    width: 100%;
    height: 100%;
  }
  
  .leftPanel {
    margin-top: 50px;
    flex: 1.75;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .profilePic {
    width: 250px;
    height: 200px;
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  
  .name {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    margin-left: 30px;
  }
  
  .body {
    
    text-align: left;
    margin-top: 10px;
  }
  
  .detail {
    font-size: 15px;
    color: #292929;
    font-weight: 500;
    margin-left: 30px;
  }
  
  .rightPanel {
    flex: 1;
    border-left: 1px solid #ccc;
    margin-right: 20px;
  }
  
  
  .marksheet {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  
  .marksheet::-webkit-scrollbar {
    width: 8px;
  }
  
  .marksheet::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .marksheet::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .marksheet::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 28px;
    color: #333;
  }
  
  @media (max-width: 768px) {
    .modalContent {
      flex-direction: column;
      width: 100%;
      max-width: none;
      height: 90vh;
      padding: 10px;
      overflow-y: auto;
    }
  
    .card {
      flex-direction: column;
    }
  
    .leftPanel, .rightPanel {
      width: 100%;
      padding: 0;
      margin-bottom: 0;
    }
  
    .rightPanel {
      border-left: none;
      border-top: 1px solid #ccc;
      padding-top: 10px;
    }
  
    .profilePic {
      width: 150px;
      height: 120px;
      border-radius: 10px;
      margin: 0 auto;
      margin-top: 20px;
    }
  
    .name {
      font-size: 18px;
      margin: 10px 0;
      padding-left: 10px;
    }
  
    .detail {
      font-size: 16px;
      margin: 5px 0;
      padding-left: 10px;
    }
  
    .closeButton {
      font-size: 24px;
      top: 5px;
      right: 5px;
    }
  }
  