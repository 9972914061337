.noDataFoundContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 200px;
  }
  
  .lottieAnimation {
    width: 40%;
    max-width: 250px;
    min-width: 200px;
    margin-bottom: 20px;
   
  }
  
  .noDataText {
    font-size: 24px;
    color: #005ff8;
    font-weight: 600;
    text-align: center;
  }

  .noDataText:hover {
    font-size: 24.5px;
    color: #0050d1;
    text-decoration: underline;
  }

  a{
    text-decoration: none;
  }
  