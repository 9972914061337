
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 14px;
}

body::-webkit-scrollbar {
  width: 8px;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
width: 40% !important;
  max-width: none !important;
  min-width: 0 !important;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.content-container.sidebar-open {
  margin-left: 250px;
}

.content-container {
  margin-left: 78px;
  transition: margin-left 0.3s ease;
  min-height: 100vh;
}

@media (max-width: 768px) {
  .content-container {
    margin-left: 50px;
    transition: margin-left 0.3s ease;
    min-height: 100vh;
  }

  .content-container.sidebar-open {
    margin-left: 200px;
  }

}
