.noDataFoundContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  
  .lottieAnimation {
    width: 40%;
    max-width: 150px;
    min-width: 100px;
    margin-bottom: 20px;
   
  }
  
  .noDataText {
    font-size: 20px;
    color: #000000;
    font-weight: 600;
    text-align: center;
  }
  